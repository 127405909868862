<template>
  <div class="registerBox">
    <div class="app-container">
      <div class="titlebox"
           @click="$router.go(-1)">
        <svg-icon style="margin-left: 22px;"
          class="svg "
          icon-class="registerLogo"></svg-icon>
        <!--<img src="/logo_collapse.png"
             alt="">
        <el-button size="medium"
                   type="text">百森eHR</el-button>!-->
      </div>
    </div>
    <section>
      <el-steps :active="active" class="stepBox" align-center finish-status="success">
          <el-step title="手机验证"></el-step>
          <el-step title="完善信息"></el-step>
          <el-step title="注册成功"></el-step>
      </el-steps>
      <el-card class="box-card">
        <el-form :model="registerForm"
                :rules="rules"
                ref="ruleForm">
          <el-form-item prop="phoneNumber"
                        v-if="active==0">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{registerForm.areaCode}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="86">+86</el-dropdown-item>
                <el-dropdown-item command="852">+852</el-dropdown-item>
                <el-dropdown-item command="886">+886</el-dropdown-item>
                <el-dropdown-item command="853">+853</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-input v-model="registerForm.phoneNumber"
                      maxlength="11"
                      placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item prop="phoneCode"
                        v-if="active==0">
            <el-input style="width:65%"
                      maxlength="6"
                      v-model="registerForm.phoneCode"
                      placeholder="请输入手机验证码"></el-input>
            <el-button class="CodeButton"
                      @click="getCode"
                      type="primary"
                      :disabled="time!=0">{{time?time+"s后重发":"获取验证码"}}</el-button>
          </el-form-item>
          <el-form-item prop="password"
                        v-if="active==0">
            <el-input type="password"
                      v-model="registerForm.password"
                      maxlength="20"
                      placeholder="请输入密码(8-20位，同时包含数字、字母)"
                      auto-complete="off" />
          </el-form-item>
          <el-form-item prop="imageCode"
                        v-if="active==0">
            <el-input style="width:65%"
                      v-model="registerForm.imageCode"
                      maxlength="4"
                      placeholder="请输入图形验证码"
                      auto-complete="off" />
            <img :src="imgurl"
                alt="Picture loading failed"
                class="codeimg"
                @click="getcaptchaimg">
          </el-form-item>
          <el-form-item prop="companyName"
                        v-if="active==1"
                        style="padding-top:30px">
            <el-input v-model="registerForm.companyName"
                      placeholder="请输入公司名称"
                      maxlength="30"
                      auto-complete="off" />
          </el-form-item>
          <el-form-item prop="name"
                        v-if="active==1">
            <el-input v-model="registerForm.name"
                      placeholder="您的姓名"
                      auto-complete="off" />
          </el-form-item>
          <el-form-item prop="mailbox"
                        v-if="active==1">
            <el-input v-model="registerForm.mailbox"
                      placeholder="您的邮箱"></el-input>
          </el-form-item>
          <el-button type="primary"
                    @click="nextStep"
                    class="nextButton"
                    :loading="reLoad"
                    v-show="active!=2">{{!active?'下一步':'注册'}}</el-button>
          <el-button type="text"
                    @click="prevStep"
                    v-if="active==1">上一步</el-button>
          <el-form-item style="text-align:center"
                        class="successBox"
                        v-if="active==2">
            <img src="../../assets/register.png" alt="" width="216" height="193">
            <!--<span style="color:#498DF0;font-size:60px;text-align:center"
                  v-show="active==2"
                  class="el-icon-success"></span>!-->
            <div>
              <p><strong>注册成功</strong></p>
              <p v-show="active==2">欢迎使用百森eHR平台！</p>
            </div>

          </el-form-item>
        </el-form>
        <p class="register-tip"
          v-show="active==0">我有账号，
          <el-link @click="$router.push({path:'/login'})">去登录</el-link>
        </p>
        <p class="register-tip"
          v-show="active==1">点击"注册"表示同意
          <span>《百森eHR服务协议》</span>
        </p>
        <p class="register-tip"
          v-show="active!=2">注册遇到问题请咨询客服（0755-1234567)
          <!-- <span> 咨询客服（0755-1234567)</span> -->
        </p>
      </el-card>
    </section>
  </div>
</template>
<script>
import md5 from 'js-md5';
import * as util from '@/utils/util';
import { getcaptchaimg } from '@/api/common';
import * as api from '@/api/loginInfo';
import * as sysApi from '@/api/workbench';
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      const re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/;

      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!re.test(value)) {
        callback(new Error('请输入8-20位字符，必须包含字母和数字'));
      }
      callback();
    };

    const validatePhone = (rule, value, callback) => {
      // var re = /^1[3456789]\d{9}$/;
      if (value === '') {
        callback(new Error('请输入手机号码'));
      } else if (value.length > 11) {
        callback(new Error('请输入正确手机号码'));
      }
      callback();
    };

    return {
      registerForm: {
        areaCode: '86',
        phoneNumber: '',
        nomd5password: '',
        key: '',
        imageCode: '',
        phoneCode: '',
        password: '',
        companyName: '',
        name: '',
        mailbox: ''
      },
      imgurl: '',
      time: 0,
      active: 0,
      reLoad: false,
      rules: {
        phoneNumber: [{ validator: validatePhone, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        imageCode: [{ required: true, trigger: 'blur', message: '请填写图形验证码' }],
        phoneCode: [{ required: true, trigger: 'blur', message: '请填写手机验证码' }],
        companyName: [{ required: true, trigger: 'blur', message: '请填写公司名称' }],
        name: [{ required: true, trigger: 'blur', message: '请填写您的姓名' }],
        mailbox: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur,change' }
        ]
      }
    };
  },
  mounted () {
    this.getcaptchaimg();
  },
  watch: {
    'active': {
      handler (val) {
        if (val == 2) {
          if (util.getCookie('token') && util.getCookie('token') != null) {
            setTimeout(() => {
              this.$router.push({ path: '/' });
            }, 3000);
          }
        }
      }
    }
  },
  methods: {
    handleCommand (command) {
      this.registerForm.areaCode = command;
      // this.$message('click on item ' + command);
    },
    async getcaptchaimg () {
      const res = await getcaptchaimg({ request: {} });

      if (res) {
        this.imgurl = `data:image/png;base64,${res.base64}`;
        this.registerForm.key = res.key;
      }
    },
    getCode () {
      api.getSms({
        request: {
          'type': '1',
          'phoneNum': this.registerForm.phoneNumber,
          'areaCode': this.registerForm.areaCode
        }
      }).then((res) => {
        let timeout;

        if (!res.code) {
          this.time = 60;
          timeout = setInterval(() => {
            this.time--;
            if (!this.time) {
              window.clearInterval(timeout);
            }
          }, 1000);
        }
      });
    },
    queryDepartment () {
      sysApi.queryDepartment({
        request: {}
      }).then((res) => {
        this.$store.commit('SET_DEPT', res);
      }).catch(() => {
      });
    },
    // 汇报对象
    selectPrincipal () {
      sysApi.selectPrincipal({
        request: {}
      }).then((res) => {
        this.$store.commit('SET_PRINCIPALLIST', res);
      }).catch(() => {
      });
    },
    prevStep () {
      this.registerForm.password = this.registerForm.nomd5password;
      --this.active;
    },
    nextStep () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.active == 0) {
            api.checkCode({
              request: {
                'areaCode': this.registerForm.areaCode,
                'phoneNumber': this.registerForm.phoneNumber,
                'checkCode': this.registerForm.phoneCode,
                'imageCode': this.registerForm.imageCode,
                'key': this.registerForm.key,
                'password': md5(this.registerForm.password)
              }
            }).then((res) => {
              if (res.code === 0) {
                this.registerForm.nomd5password = this.registerForm.password;
                this.active++;
              }
            });
          } else if (this.active === 1) {
            this.registerForm.password = md5(this.registerForm.password);
            this.reLoad = true;
            api.register({
              request: this.registerForm
            }).then((res) => {
              if (res.code == 0) {
                this.active++;
                this.$store.dispatch('app/setPermission');
                this.$store.dispatch('app/getDict');
                this.queryDepartment();
                this.selectPrincipal();
              }
              this.reLoad = false;
            });
          }
        } else {
          return false;
        }
      });

    }
  }
};
</script>
<style lang="scss">
.register-tip {
  margin-top: 12px;
  color: #a1a1a1;
  .el-link {
    transform: translateY(-1px);
  }
}
.registerBox {
  .stepBox {
    .is-process,.is-success {
      .el-step__icon.is-text {
        background-color:#498DF0;
        color: white;
        border: none;
      }
    // .el-icon-check:before {
    //   content: "1";
    //   }
    }
    .el-step__title.is-process,.el-step__title.is-success {
      color:#498DF0;
    }
    .is-success .el-step__line{
       background-color:#498DF0;
       border-color:#498DF0;
    }
  }
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .svg-icon {
      width: 230px;
      height: 50px;
    }
  }
  section {
    width: 70%;
    background-color: #fff;
    padding-top: 50px;
    margin: 0 auto;
  }
  img {
    vertical-align: bottom;
    float: left;
    margin-right: 10px;
  }
  .titlebox {
    text-align: left;
    line-height: 17px;
    overflow: hidden;
    background: #fff;
    font: bold 18px/25px "Microsoft YaHei";
    padding: 14px 40px;
    > .el-button {
      color: #484848;
      transform: translateY(0);
      font-size: 16px;
    }
    margin: 20px;
  }
  .box-card {
    width: 650px;
    margin: 20px auto 0;
    box-shadow: none;
    border:none;
    padding: 0px 115px 30px;
    .el-form {
      display: flex;
      flex-direction: column;
      //   text-align: center;
      .el-input:nth-child(2) .el-input__inner {
        padding-left: 80px;
      }
      //   .el-form-item__error {
      //     left: 125px;
      //   }
      .successBox {
        img {
          display: block;
          float:none;
          margin: 0 auto;
        }
        p {
          color:#7A7A7A;
          margin: 20px 0 -20px;
          transform: translateX(12px);
          font-size: 16px;
        }
        p:first-of-type{
          font-size: 22px;
          color:#498DF0;
        }
      }
    }
    .el-dropdown {
      z-index: 12;
      position: absolute;
      left: 20px;
      padding-right: 10px;
      border-right: 1px solid #DCDFE6
    }
    .el-input {
      width: 100%;
    }
    .CodeButton {
      position: absolute;
      right: 0;
      height: 40px;
      color: #fff;
    }
    .nextButton {
      width: 100%;
      margin: 22px auto 0;
    }
    .codeimg {
      position: absolute;
      right: -8px;
      height: 40px;
      border: 1px solid #dbdbdb;
    }
    p {
      //   margin-left: 126px;
      font-size: 14px;
      span {
        color: #3a8ee6;
        cursor: pointer;
      }
    }
  }
}
</style>
